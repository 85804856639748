import React, { useState } from 'react';
import { Plus, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';
import './LeftSidebar.css';

const DEFAULT_AVATAR = '/assets/images/amit_shine.jpg';

interface Chat {
  id: string;
  name: string;
  avatar: any;
  lastMessage?: string;
  timestamp?: Date;
}

interface LeftSidebarProps {
  chats?: Chat[];
  selectedChatId?: string;
  onSelectChat: (chat: Chat) => void;
  onCreateNewChat: () => void;
}

const LeftSidebar: React.FC<LeftSidebarProps> = ({ 
  chats = [], 
  selectedChatId, 
  onSelectChat, 
  onCreateNewChat 
}) => {
  // State to track whether the sidebar is collapsed
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  // Function to toggle the collapsed state
  const toggleSidebar = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`left-sidebar-container ${isCollapsed ? 'collapsed' : ''}`}>
      {/* Logo */}
      <div className="logo">
        {!isCollapsed && (
          <>
            <img 
              src="/assets/images/hitcraft_logo.png" 
              alt="HitCraft Logo" 
              className="hitcraft_logo" 
            />
            <img
              src="/assets/images/layer-18.png"
              alt="Collapse Pane"
              className="collapse-icon"
              onClick={toggleSidebar}
            />
          </>
        )}
        {isCollapsed && (
          <img
            src="/assets/images/layer-18.png"
            alt="Expand Pane"
            className="collapse-icon"
            onClick={toggleSidebar}
          />
        )}
      </div>

      {/* Separator Line Below Logo */}
      <div className="separator-line separator-line-top" />

      {/* Create Button */}
      <button className="create-button" onClick={onCreateNewChat}>
        <Plus 
          size={16} 
          style={{
            position: 'absolute',
            left: '20px',
            top: '50%',
            transform: 'translateY(-50%)'
          } as React.CSSProperties} 
        />
        {!isCollapsed && <span className="create-text">Create</span>}
      </button>

      {/* Navigation Links */}
      <nav className="sidebar-nav">
        <ul>
          <li>
            <a href="#">
              <img 
                src="/assets/images/layer-23.png" 
                alt="Find Music Professionals" 
                className="nav-icon" 
              />
              {!isCollapsed && <span className="nav-text">Find music professionals</span>}
            </a>
          </li>
          <li>
            <a href="#">
              <img 
                src="/assets/images/layer-24.png" 
                alt="My Songs" 
                className="nav-icon" 
              />
              {!isCollapsed && <span className="nav-text">My songs</span>}
            </a>
          </li>
        </ul>
      </nav>

      {/* Separator Line */}
      <div className="separator-line separator-line-middle" />

      {/* Chat History */}
      <div className="chat-history">
        <h3>{!isCollapsed && 'Chat history'}</h3>
        <ul>
          {chats.map((chat) => (
            <li key={chat.id} onClick={() => onSelectChat(chat)}>
              <div className={`chat-item ${chat.id === selectedChatId ? 'active' : ''}`}>
                <div className="chat-avatar">
                  <img
                    // src={chat.avatar}
                    src={!chat.avatar.image_data ? `${chat.avatar}` : `data:image/png;base64,${chat.avatar.image_data}`}
                    alt={chat.name}
                  />
                </div>
                {!isCollapsed && (
                  <div className="chat-info">
                    <span className="chat-name">{chat.name}</span>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Bottom Links */}
      {!isCollapsed && (
        <div className="bottom-links">
          <a href="#">About HitCraft</a>
          <span className="separator-dot">•</span>
          <a href="#">Terms of Service</a>
        </div>
      )}

      {/* Separator Line Above User Settings */}
      <div className="separator-line separator-line-bottom" />

      {/* User Settings (Bottom Avatar) */}
      <div className="user-settings">
        {/* <img src={DEFAULT_AVATAR} alt="User" className="user-avatar" /> */}
        <img src={'https://ca.slack-edge.com/T026G3AS47N-U025V5EU8SK-8b42ee20d7f3-512'} alt="User" className="user-avatar" />
        {!isCollapsed && <span className="user-name">Oudi_2x4</span>}
        {!isCollapsed && (
          <span className="dropdown-arrow">
            <ChevronDown size={14} />
          </span>
        )}
        {isCollapsed && (
          <ChevronRight 
            size={16} 
            className="expand-arrow" 
            onClick={toggleSidebar} 
          />
        )}
      </div>
    </div>
  );
};

export default LeftSidebar;