import React, { useState, useRef } from 'react';
import { InfoIcon, Download, Forward, Share2, Pause, Play } from 'lucide-react';

interface AudioTrackProps {
  trackNumber?: number;
  onProgressChange: (newProgress: number) => void;
  progress?: number;
  audioSrc: string;
  onPlay: () => void;
  onPause: () => void;
  onDownload: () => void;
  onForward: () => void;
  onShare: () => void;
  onInfo: () => void;
}

const AudioTrack: React.FC<AudioTrackProps> = ({
  trackNumber = 2,
  audioSrc,
  onPlay,
  onPause,
  onDownload,
  onForward,
  onShare,
  onInfo,
  onProgressChange,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  const togglePlayPause = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Prevent triggering the handleSeek event
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      onPause();
    } else {
      audioRef.current.play();
      onPlay();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgress = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      const duration = audioRef.current.duration;
      const newProgress = (currentTime / duration) * 100;
      setProgress(newProgress);
      onProgressChange(newProgress);
    }
  };

  const handleSeek = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!audioRef.current) return;
    const rect = e.currentTarget.getBoundingClientRect();
    const newProgress = (e.clientX - rect.left) / rect.width;
    const newTime = newProgress * audioRef.current.duration;
    audioRef.current.currentTime = newTime; // Seek to new time
    setProgress(newProgress * 100); // Update progress bar
    onProgressChange(newProgress * 100); // Report seek progress
  };

  return (
    <div className="relative w-full max-w-lg rounded-2xl p-3 bg-gray-200">
      {/* Top section with title and actions */}
      <div className="mb-2 flex items-center justify-between">
        <div className="text-left text-[15px] font-light text-gray-700">
          Track {trackNumber}
        </div>
        <div className="flex items-center gap-3">
          <button onClick={onInfo} className="text-gray-500">
            <InfoIcon size={20} />
          </button>
          <button onClick={onDownload} className="text-gray-500">
            <Download size={20} />
          </button>
          <button onClick={onForward} className="text-gray-500">
            <Forward size={20} />
          </button>
          <button onClick={onShare} className="text-gray-500">
            <Share2 size={20} />
          </button>
        </div>
      </div>

      {/* Audio element */}
      <audio
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={handleProgress} // Track progress on time update
        onEnded={() => setIsPlaying(false)} // Reset isPlaying when audio ends
      />

      {/* Custom play/pause button and progress bar */}
      <div
        onClick={handleSeek}
        className="relative h-8 w-full cursor-pointer overflow-hidden rounded-3xl bg-gray-900 px-3 py-1 mt-2"
      >
        {/* Progress gradient overlay */}
        <div
          className="absolute left-0 top-0 h-full bg-gradient-to-r from-purple-600 to-red-600 transition-all duration-100 ease-out"
          style={{ width: `${progress}%` }}
        />

        {/* Play and pause buttons */}
        <button
          onClick={togglePlayPause} // Toggle play and pause
          className="absolute left-3 top-1/2 flex h-6 w-6 -translate-y-1/2 items-center justify-center rounded-full border-0 bg-transparent text-white cursor-pointer"
        >
          {isPlaying ? <Pause size={14} /> : <Play size={14} />}
        </button>
      </div>
    </div>
  );
};

export default AudioTrack;