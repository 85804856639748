import React, { ChangeEvent } from 'react';
import './uploadButton.css';

interface UploadButtonProps {
  onUpload: (file: File) => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({ onUpload }) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onUpload(file);
    }
  };

  return (
    <div 
      className="-e-Rectangle-3-copy-78"
      style={{
        width: '172px',
        height: '74px',
        margin: '3.9px 0 10px 0',
        padding: '20px',
        borderRadius: '17px',
        backgroundColor: '#e3e9f7',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div 
        className="Rectangle-2-copy-88"
        style={{
          width: '142px',
          height: '34px',
          padding: '0 15px',
          borderRadius: '17px',
          backgroundImage: 'linear-gradient(to right, #712bc2 -25%, #31ade1 125%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <label 
          htmlFor="file-upload" 
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '7.7px',
            cursor: 'pointer',
            color: '#fff',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '13.5px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
          }}
        >
          <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 0L9 4.5H6.75V12H2.25V4.5H0L4.5 0Z" fill="white"/>
          </svg>
          UPLOAD FILE
        </label>
        <input 
          type="file" 
          id="file-upload" 
          accept="audio/*"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default UploadButton;