import React from 'react';
import './styles.css';

interface ProgressBarProps {
  title?: string;
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ 
  title = 'Producing your song', 
  progress = 0 
}) => {
  return (
    <div className="-e-Rectangle-3-copy-87">
      <span className="text-left text-[15px] font-[300] text-[#424246]">
        {title}
      </span>
      
      <div className="relative w-full h-2 mt-2">
        {/* Background bar */}
        <div 
          className="absolute top-0 left-0 w-full h-2 rounded-full bg-blue-200"
        />
        
        {/* Progress bar overlay */}
        <div 
          className="absolute top-0 left-0 h-2 rounded-full bg-gradient-to-r from-purple-600 to-red-600"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;