import React from 'react';
import './RightPane.css';

interface Sample {
  name: string;
  listens: string;
}

interface User {
  id: string;
  name: string;
  avatar: string;
  title: string;
}

interface RightPaneProps {
  selectedChatId?: string;
  user: User | null;
  samples: Sample[];
  onCreateNewChat: () => void;
  onShowForm: () => void;
}

const RightPane: React.FC<RightPaneProps> = ({
  user,
  samples,
  selectedChatId,
  onCreateNewChat,
  onShowForm
}) => {
  if (!user) return null;

  return (
    <div className="user-info">
      <div className="user-header">
        <div className="avatar">
          <img src={user.avatar} alt={user.name} />
        </div>
        <div className="user-details">
          <h2 className="user-name">
            {user.name} <span className="verified-badge">✓</span>
          </h2>
          <p className="user-title">{user.title}</p>
        </div>
      </div>
      <div className="header-separator" />

      <div className="about-section">
        <h3>About</h3>
        <p>{user.name} is an accomplished music professional with expertise in their field.</p>
      </div>
      <div className="about-separator" />

      <div className="production-samples">
        <h3>
          Production Samples 
          <span className="see-all">See all</span>
        </h3>
        <ul>
          {samples.map((sample, index) => (
            <li key={index}>
              <button className="play-button">
                <div className="play-icon" />
              </button>
              <div className="sample-info">
                <div className="sample-title production-title">{sample.name}</div>
                <span className="sample-listens">{sample.listens} listens</span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="social-separator" />
      <div className="more-options">
        <div className="option-buttons">
          <button className="option-button">
            <img 
              src={`${process.env.PUBLIC_URL}/assets/apple.png`} 
              alt="Apple Music" 
            />
          </button>
          <button className="option-button">
            <img 
              src={`${process.env.PUBLIC_URL}/assets/youtube.png`} 
              alt="YouTube" 
            />
          </button>
          <button className="option-button">
            <img 
              src={`${process.env.PUBLIC_URL}/assets/spotify.png`} 
              alt="Spotify" 
            />
          </button>
        </div>
        <div className="followers Rectangle-3-copy-82">
          <img 
            src={`${process.env.PUBLIC_URL}/assets/layer-6.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/layer-6@2x.png 2x, ${process.env.PUBLIC_URL}/assets/layer-6@3x.png 3x`}
            alt="Like"
            className="Layer-6"
          />
          <span className="K">26.1K</span>
          <div className="Layer-15" />
          <img 
            src={`${process.env.PUBLIC_URL}/assets/layer-6-copy.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/layer-6-copy@2x.png 2x, ${process.env.PUBLIC_URL}/assets/layer-6-copy@3x.png 3x`}
            alt="Share"
            className="Layer-6-copy"
          />
        </div>
      </div>
      <div className="more-options-separator" />
      <div className="action-buttons">
        <button 
          className="produce-song-button" 
          onClick={onShowForm}
        >
          Produce This Song
        </button>
      </div>
    </div>
  );
};

export default RightPane;