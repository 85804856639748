import React, { FC } from 'react';
import './styles.css';

interface StyleOption {
  id: string;
  label: string;
  genre: string;
  isSelected?: boolean;
}

interface StylePickerPairProps {
  styles: [StyleOption, StyleOption];  // Exactly two styles
  onStyleSelect?: (styleId: string) => void;
}

const StylePickerPair: FC<StylePickerPairProps> = ({ 
  styles, 
  onStyleSelect 
}) => {
  return (
    <div className="style-picker-container">
      {styles.map((style, index) => (
        <div 
          key={style.id}
          className={index === 0 ? "-e-Rectangle-3-copy-88" : "-e-Rectangle-3-copy-90"}
          style={{
            width: '284px',
            height: '48px',
            margin: 0,
            padding: '8px 11.8px 8px 12px',
            borderRadius: '17px',
            backgroundColor: '#e3e9f7',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            flexShrink: 0
          }}
        >
          <span 
            style={{
              fontFamily: 'var(--font-family)',
              fontSize: '15px',
              fontWeight: '300',
              lineHeight: '1.6',
              color: '#000',
              width: '125px'
            }}
          >
            {`${style.label} | ${style.genre}`}
          </span>
          
          <div 
            className={style.isSelected ? "Ellipse-5" : "Ellipse-5-copy-2"}
            onClick={() => onStyleSelect?.(style.id)}
            style={{
              width: '28px',
              height: '28px',
              position: 'absolute',
              right: '11.8px',
              borderRadius: '50%',
              ...(style.isSelected 
                ? {
                    backgroundImage: 'linear-gradient(to right, #8a44c8 -25%, #df0c39 125%)'
                  } 
                : {
                    backgroundColor: '#b2c3e6'
                  }
              ),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              padding: '8px',
              boxSizing: 'border-box'
            }}
          >
            <div style={{ color: 'white', fontSize: '16px' }}>▶</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StylePickerPair;