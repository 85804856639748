import React from 'react';

const TypingIndicator = () => {
  return (
    <div className="message-bubble" style={{ backgroundColor: 'rgb(227, 233, 247)', minWidth: '60px', width: 'fit-content' }}>
      <div className="flex space-x-2">
        <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" 
             style={{ animationDelay: '0ms' }} />
        <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" 
             style={{ animationDelay: '150ms' }} />
        <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" 
             style={{ animationDelay: '300ms' }} />
      </div>
    </div>
  );
};

export default TypingIndicator;