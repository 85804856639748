import React, { useState, useRef, useEffect } from 'react';
import { ArrowUpFromLine, Play, Pause } from 'lucide-react';
import './CenterPane.css';
import ProgressBar from './components/ProgressBar';
import UploadButton from './components/uploadButton';
import { Message } from './types/chat';
import AudioTrack from './components/audioTrack';

// Constants
const DEFAULT_AVATAR = '/assets/images/max_martin.png';
const ASSISTANT_AVATAR = 'https://ca.slack-edge.com/T026G3AS47N-U025V5EU8SK-8b42ee20d7f3-512';

interface User {
  id: string;
  name: string;
  avatar?: string;
}

interface CenterPaneProps {
  selectedUser: User | null | any;
  selectedChatId?: string;
  showChatForm: boolean;
  onChatFormSubmitted: (formData: any) => void;
  messages: Message[];
  onSendMessage: (message: string, mock?: boolean) => void;
  onHandleMockAnswer: (message: string) => void;
  onFileUpload: (file: File, message?: string) => void;
  isTyping: boolean;
  onShowForm: () => void;
}

const CenterPane: React.FC<CenterPaneProps> = ({
  selectedUser,
  selectedChatId,
  showChatForm,
  onChatFormSubmitted,
  messages,
  onSendMessage,
  onHandleMockAnswer,
  onFileUpload,
  isTyping,
  onShowForm
}) => {
  const [inputText, setInputText] = useState<string>('');
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isProduceSong, setIsProduceSong] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  
  const messageListRef = useRef<HTMLDivElement | null>(null);
  const latestMessageRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  // Cleanup audio player when changing
  useEffect(() => {
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
  }, [audioUrl]);

  const handleUploadFile = (uploadedFile: File) => {
    setFile(uploadedFile);
    onFileUpload(uploadedFile);
  };

  const handleClearHistory = async () => {
    try {
      console.log('Cleaning history...');
      await fetch(`https://platform.session42.xyz/chat/${selectedChatId}/clear`, {
        method: 'POST'
      });
      console.log('History cleaned');
      alert('History cleaned');
      window.location.reload();
    } catch (error) {
      console.error('Error clearing history:', error);
      alert('Failed to clear history');
    }
  };

  const handleSendMessage = () => {
    if (inputText.trim()) {
      const message = inputText.trim();

      if (message.toLowerCase() === 'delete history') {
        handleClearHistory();
        setInputText('');
      } else if (message.toLowerCase().includes('produce my song')) {
        onShowForm();
        setInputText('');
      } else {
        setInputText('');
        onSendMessage(message, false);
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handlePlayPause = (audioSrc: string) => {
    // if (!audioSrc) return;

    // if (!audioRef.current) {
    //   audioRef.current = new Audio(audioSrc);
    //   audioRef.current.onended = () => {
    //     setIsPlaying(false);
    //     audioRef.current = null;
    //   };
    // }

    // if (isPlaying) {
    //   audioRef.current.pause();
    //   setIsPlaying(false);
    // } else {
    //   audioRef.current.play()
    //     .then(() => setIsPlaying(true))
    //     .catch(error => {
    //       console.error('Error playing audio:', error);
    //       setIsPlaying(false);
    //     });
    // }
  };

  const handleProgressChange = (newProgress: number) => {
    console.log('New Progress:', newProgress);
    // if (audioRef.current) {
    //   const newTime = (newProgress / 100) * audioRef.current.duration;
    //   audioRef.current.currentTime = newTime;
    //   setProgress(newProgress);

    //   console.log('Set New Progress:', newProgress);
    // }
  };

  const renderMessageContent = (message: Message) => {
    if (typeof message.content === 'string') {

      if (message.isMusic) {
        return (
          <AudioTrack
            trackNumber={1}
            audioSrc={message.content}
            progress={progress}
            onPlay={() => handlePlayPause(message.content as string)}
            onPause={() => handlePlayPause(message.content as string)}
            onDownload={() => console.log('Download')}
            onForward={() => console.log('Forward')}
            onShare={() => console.log('Share')}
            onInfo={() => console.log('Info')}
            onProgressChange={handleProgressChange}
          />
        );
      }

      return (
        <div className="message-bubble">
          <span>{message.content}</span>
          {message.isMusic && (
            <button 
              className="play-button-styled"
              onClick={() => handlePlayPause(message.content as string)}
            >
              {isPlaying ? <Pause size={20} /> : <Play size={20} />}
            </button>
          )}
        </div>
      );
    }
    return message.content;
  };

  useEffect(() => {
    let progressInterval: any;

    if (isPlaying && audioRef.current) {
      progressInterval = setInterval(() => {
        // @ts-ignore
        const { currentTime, duration } = audioRef.current;
        if (duration) {
          const currentProgress = (currentTime / duration) * 100;
          // @ts-ignore
          setProgress(currentProgress);
        }
      }, 100); // Update every 1 second
    } else if (!isPlaying && progressInterval) {
      clearInterval(progressInterval); // Clear interval when paused
    }

    return () => clearInterval(progressInterval); // Cleanup on unmount
  }, [isPlaying]);

  return (
    <div className="chat-container">
      {selectedUser && !showChatForm ? (
        <>
          <div className="message-list" ref={messageListRef} style={{marginBottom: '97px'}}>
            <div className="chat-header">
              <div className="header-content">
                <div className="header-avatar">
                  <img 
                    src={selectedUser.avatar || DEFAULT_AVATAR} 
                    alt={selectedUser.name}
                  />
                </div>
                <div className="header-info">
                  <h2>{selectedUser.name}</h2>
                </div>
              </div>
            </div>

            {messages.map((message, index) => (
              <div key={index}>
                <div className={`message-wrapper ${message.isRightSide ? 'right-message' : ''}`}>
                  <div className="avatar-container">
                    <div className="avatar">
                      <img
                        src={!message.isRightSide ? selectedUser.avatar || DEFAULT_AVATAR : ASSISTANT_AVATAR}
                        alt={!message.isRightSide ? "User Avatar" : "Assistant Avatar"}
                      />
                    </div>
                    <span className="username">
                      {!message.isRightSide ? selectedUser.name : "You"}
                    </span>
                  </div>
                  <div className="message-content">
                    {isProduceSong && !message.isRightSide && index === messages.length - 1 ? (
                      <UploadButton onUpload={handleUploadFile} />
                    ) : (
                      renderMessageContent(message)
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="input-container">
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Type your message..."
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <input 
                type="file"
                accept="audio/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    handleUploadFile(file);
                    e.target.value = '';
                  }
                }}
              />
              <button 
                className="attach-button" 
                onClick={() => fileInputRef.current?.click()}
              >
                <ArrowUpFromLine size={20} />
              </button>
              <button className="send-button" onClick={handleSendMessage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m3 3 3 9-3 9 19-9Z" />
                  <path d="M6 12h16" />
                </svg>
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="no-chat-selected">
          {showChatForm ? (
            <div>Loading chat form...</div>
          ) : (
            <p>Select a chat to start messaging</p>
          )}
        </div>
      )}
    </div>
  );
};

export default CenterPane;